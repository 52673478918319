import React, { useEffect, useState } from 'react';
import Tooltip from "@mui/material/Tooltip";


const ProgresBar = ({ sessionDuration, currentTime, listingMessage, onClickSeeker }) => {
    const seconds = parseInt(((currentTime / 1000)));
    // console.log("ProgresBarProgresBar ", seconds, sessionDuration, (seconds / sessionDuration) * 100, listingMessage)
    // const onClickSeeker = (t) => {
    //    t
    // }
    return (
        <>
        
        <div style={{zIndex:"1000"}} id='toolTip'  className="absolute w-[35rem] h-[0.1rem] bottom-0 left-5  bg-white mb-[1rem] content-none"  >
            {/* <div className="absolute left-0 top-[-0.2rem] w-[0.5rem] h-[0.5rem] bg-green-600 rounded-full content-none"></div>
            <div className="absolute right-0 top-[-0.2rem] w-[0.5rem] h-[0.5rem] bg-green-600 rounded-full content-none"></div> */}
            <div style={{ left: `${(seconds / sessionDuration) * 100}%`,zIndex:"1000" }} className="flex justify-center items-center absolute right-0 top-[-0.4rem] w-[0.8rem] h-[0.8rem]  bg-[var(--primary-color)]   rounded-full content-none">
            <div  className='rounded-full content-none bg-white w-[0.3rem] h-[0.3rem]' ></div>
            </div>
            {
                listingMessage?.map((v, i) => {
                    // console.log("sffffffffff",v["lapsedTime"]);
                    return (
                        <Tooltip title={`${v["message"]}  ${v["lapsedTime"]} ${v?.date}`} arrow>
                            <div style={{ left: `${(v["lapsedTime"] / sessionDuration) * 100}%` }}
                                className={`absolute top-[-0.1rem] w-[0.3rem] h-[0.3rem] bg-black rounded-full content-none`} onClick={()=>onClickSeeker(v["lapsedTime"],v,i,listingMessage)}></div>
                                {/* <div style={{background:{color},left: `${(v["lapsedTime"] / sessionDuration) * 100}%`,
                                width:`${(v["lapsedTime"] / sessionDuration) * 100}rem`
                                }} className={`absolute top-[-0.1rem] border-blue-500 border-r-2 bg-red-300 h-[0.2rem] content-none`}></div>   */}
                        </Tooltip>
                    )
                })
            }
        </div>
        {/* ${(v["lapsedTime"] / sessionDuration) * 40} */}
     
        
        </>
    );
}

export default ProgresBar;
