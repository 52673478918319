import { AgGridReact } from "ag-grid-react"; // React Data Grid Component
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the Data Grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the Data Grid

import React, { useState } from "react";

function LogTable(props) {
  const { listingMessage } = props;

  const [colums, setColumns] = useState([
    { field: "date" },
    { field: "key" },
    { field: "lapsedTime" },
    { field: "message" },
    { field: "type" },
  ]);
  return (
    <div
      className="ag-theme-quartz" // applying the Data Grid theme
      style={{ height: 500 }} // the Data Grid will fill the size of the parent container
    >
      <AgGridReact rowData={listingMessage} columnDefs={colums} />
    </div>
  );
}

export default LogTable;
// listingMessage?.map((item, index) => {
//     return (
//       <div
//         className="flex gap-2 h-[2rem]  text-sm  "
//         key={item.key}
//       >
//         <p
//           className={` ${
//             item?.type === "error"
//               ? "text-red-400"
//               : item?.type === "warning"
//               ? "text-orange-400"
//               : "text-black"
//           }`}
//         >
//           <div className="flex">
//             <p className="text-black flex justify-center w-[2rem]">
//               {listingMessage?.length - index}.
//             </p>
//             <p>{item?.message}</p>
//           </div>
//         </p>
//         <p className=""> - {item?.date}</p>
//       </div>
//     );
//   })
